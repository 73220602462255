<template>
    <div class="modal">
        <div @click="closeModal" class="close-btn">
            <IconClose />
        </div>
        <div class="modal-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import IconClose from "@/assets/svg/icon-x.svg";

export default {
    name: 'Modal',
    components: {
        IconClose,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        }
    },
    mounted() {
        const event = new Event("build", { bubbles: true });
        this.$refs.slideshow.dispatchEvent(event);
        console.log('🇬🇷');
    },
}
</script>
