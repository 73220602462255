<template>
    <div v-html="block.value.content" ref="wysiwygRef" class="wysiwyg"></div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'
import { openInNewWindow } from "@/utils/url";

export default {
    name: 'WysiwygBlock',
    components: {
        LoadingIndicator
    },
    props: {
        block: Object
    },
    methods: {
        handleClick(event) {
            if (event.target.tagName === 'IMG') {
                const originalSrc = event.target.src.replace(/(\.[^.]+)\.(\w+)$/, '.original.$2');
                const href = `/image-viewer?src=${encodeURIComponent(originalSrc)}`;

                openInNewWindow(href);
            }
        },
    },
    mounted() {
        this.$refs.wysiwygRef.addEventListener('click', this.handleClick);
    }
}
</script>

<style scoped>
.wysiwyg::v-deep img {
    width: 100%;
    height: auto;
}

.wysiwyg::v-deep img:hover {
    cursor: pointer;
}
</style>
