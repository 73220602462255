<template>
    <div>
        <div v-if="record">
            <section class="record-details wrapper-title">
                <div class="record-details-title">
                    <h1>{{ record.title }}</h1>
                    <p class="byline" v-if="record.authors.length > 0">by
                        <span class="author" v-for="author in record.authors" :key="author.slug">
                            {{ author.name }}
                        </span>
                        <!-- <router-link v-for="author in record.authors" :key="author.slug" class="author"
                            :to="{name: 'author', params: {slug: author.slug}, hash: '#' + format_url(author)}">
                        {{ author.name }}
                    </router-link> -->
                    </p>
                    <dl class="metadata">
                        <div v-if="record.groups.length > 0">
                            <dt class="sr-only">Group:</dt>
                            <dd v-for="g in record.groups" :key="g.name">
                                <p>{{ g.name }}</p>
                            </dd>
                        </div>

                        <div v-if="record.features && record.features.length > 0">
                            <dt>Feature:</dt>
                            <dd v-for="g in record.features" :key="g.name">
                                <p>{{ g.name }}</p>
                            </dd>
                        </div>

                        <div v-if="record.landscape_character">
                            <dt>Landscape Character:</dt>
                            <dd>
                                {{ record.landscape_character.name }}
                            </dd>
                        </div>

                        <div v-if="record.is_trail">
                            <dt>Trail:</dt>
                            <dd>
                                <p>Yes</p>
                            </dd>
                        </div>
                    </dl>
                </div>
                <div class="record-details-identifier">
                    <span class="sr-only">Map entry number</span>
                    <span class="record-identifier" :class="[markerColor(record)]">{{ record.identifier }}</span>
                </div>
            </section>

            <section v-if="record.content.length > 0" class="wrapper-content">
                <StreamField :streamfield="record.content" />
            </section>


            <section v-if="record.more_info_title || more_links.length" class="wrapper-content">
                <h4>{{ record.more_info_title }}</h4>

                <ul class="more_info">
                    <li v-for="(link, index) in more_links" :key="index">
                        <LinkBlock :link="link.value" :linkType="link.type" />
                    </li>
                </ul>
            </section>

            <section v-if="record.citations.length > 0" class="wrapper-content">
                <h4>Citations</h4>
                <ul class="citations text-small">
                    <li v-for="(cite, index) in record.citations" :key="index" v-html="cite" />
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import StreamField from "@/components/StreamField"
import Loading from "@/components/Loading"
import LinkBlock from "@/components/blocks/LinkBlock"

export default {
    name: 'Record',
    components: {
        Loading,
        StreamField,
        LinkBlock
    },
    data: function () {
        return {
            seo_title: '',
            seo_desc: ''
        }
    },
    computed: {
        ...mapGetters({
            'loading': 'records/record_loading',
            'record': 'records/record'
        }),
        'content': function () {
            return this.record.content
        },
        'content_images': function () {
            const images = [];
            this.record.content.forEach(block => {
                if (block.type === 'carousel') {
                    block.value.forEach(image => images.push(image.value.image));
                } else if (block.type === 'image') {
                    images.push(block.value);
                }
            });
            return images;
        },
        'more_links': function () {
            return this.record.more_info_links
        },
    },
    head: {
        title: function () {
            return {
                inner: this.record ? this.record.title : ''
            }
        },
        meta: function () {
            return [
                { name: 'description', content: this.seo_desc, id: 'seo-desc' },
                { name: 'og:description', content: this.seo_desc, id: 'og-desc' },
                { name: 'og:title', content: this.seo_title, id: 'og-title' }
            ]
        }
    },
    methods: {
        load(slug) {
            this.$store.dispatch('records/fetch', slug)
        },
        format_url(author) {
            return author.name.toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/\s/g, '-');
        },

        /**
         * Returns color for current group selected
         */
        markerColor(record) {
            return record.groups.length ? record.groups[0].marker_color : '';
        },

    },
    mounted() {
        this.load(this.$route.params.slug)
    },
    watch: {
        '$route.params.slug': function () {
            this.load(this.$route.params.slug)
        },
        record: function () {

            // let desc = new DOMParser().parseFromString(this.record.description, 'text/html').querySelector('p').textContent;
            // desc = desc.substring(0, 300);
            // desc += '...';

            this.seo_title = this.record.title;
            // this.seo_desc = desc;

            this.$emit('updateHead')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-weight: 400;
}

h2,
h3 {
    margin-bottom: 0.5rem;
}

h4 {
    margin-bottom: 0 !important;
}

.byline {
    .author {
        display: inline;
        margin: 0;
        margin-right: .35rem;

        &:not(:last-child):after {
            content: ',';
        }
    }
}

.metadata {
    margin: 0;

    dt {
        display: inline-block;
        margin-right: .35rem;
    }

    dd {
        display: inline;
        margin: 0;
        margin-right: .35rem;

        &:not(:last-child) p:after {
            content: ',';
        }

        p {
            display: inline;
            margin: 0;
        }
    }
}

.more_info {
    list-style: none;
    padding: 0;
}

.citations {
    list-style: none;
    padding: 0;

    p {
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
    }
}

.record-details {
    display: grid;
    grid-template-columns: 95% 5%;

    .record-details-title {
        grid-column: 1;
        grid-row: 2;
        margin-top: $padding-unit-v;
    }

    .record-details-identifier {
        grid-column: 1;
        grid-row: 1;

        .record-identifier {
            font-family: $font-sans;
            background: $color-black;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: .9rem;
            margin-left: 0;
            padding: 0;
            width: 40px;
            height: 40px;

            &.terra-cotta {
                background-color: $marker-terra-cotta;
            }

            &.magenta {
                background-color: $marker-magenta;
            }

            &.light-blue {
                background-color: $marker-light-blue;
            }

            &.dark-blue {
                background-color: $marker-dark-blue;
            }

            &.green {
                background-color: $marker-green;
            }

            &.gold {
                background-color: $marker-gold;
            }
        }
    }
}

// .wrapper-title + .wrapper-content {
//     padding-top: 0;
// }

.wrapper-content {
    border-top: 1px solid $color-gray-light;
}
</style>
