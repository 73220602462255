import Vue from 'vue'

export const state = () => ({
    features: null,
    features_error: null,
    features_loading: false,

    record: null,
    record_error: null,
    record_loading: null,
    record_groups: [],
    record_groups_names: [],

    records: null,
    records_src: null,
    records_error: null,
    records_loaded: false,
    records_loading: false
})

export const getters = {
    features: state => state.features,
    features_error: state => state.features_error,
    features_loading: state => state.features_loading,

    record: state => state.record,
    record_error: state => state.record_error,
    record_loading: state => state.record_loading,
    record_groups: state => state.record_groups,
    record_groups_names: state => state.record_groups_names,

    records: state => state.records,
    records_error: state => state.records_error,
    records_loaded: state => state.records_loaded,
    records_loading: state => state.records_loading
};

export const actions = {
    /**
     * Toggle between different record sets or groups.
     * @param {*} param0
     * @param {*} set
     */
    change_record_set({commit}, record_set) {
        commit('setRecordSet', record_set)
    },

    /**
     * Retrieve a specific record.
     * @param {*} param0
     * @param {*} slug
     */
    fetch({commit}, slug) {
        if (!this.state.global.site) return;
        commit('setRecordLoading', true)

        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/map/${this.state.global.site}-records/${slug}/`)

        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                commit('setRecord', req.response)
            } else {
                commit('setRecordError', `Record fetch error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            commit('setRecordError', `Record fetch request failed.`)
        }
    },

    /**
     * Retrieve the list of DAG record features to be used in filtering map
     * pins and guard against loading a second time.
     * @param {*} param0
     */
    fetch_features({commit, state}) {
        if(state.features) {
            return
        }

        commit('setFeaturesLoading', true)

        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/dag/features/`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                commit('setFeatures', req.response)
            } else{
                commit('setFeaturesError', `Features request error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            commit('setFeaturesError', `Features request failed.`)
        }
    },

    /**
     * Retrieve the list of  Records and guard against loading a second
     * time.
     * @param {*} param0
     */
    fetch_list({commit, dispatch, state}) {
        if(state.records) {
            return
        }

        commit('setRecordsLoading', true)


        let req = new XMLHttpRequest()
        req.open('GET', `${window.location.origin}/api/map/${this.state.global.site}-records`)
        req.responseType = 'json'
        req.send()
        req.onload = () => {
            if(req.status === 200) {
                commit('setRecords', req.response)
                dispatch('map/setMapColorKey', req.response, {root:true})
                dispatch('map/setActiveGroups', req.response, {root:true})
            } else{
                commit('setRecordsError', `Records fetch_list error ${req.status} : ${req.statusText}`)
            }
        }
        req.onerror = () => {
            commit('setRecordsError', `Records fetch_list request failed.`)
        }
    },

    /**
     * Filter the map features (records), this is done by filtering the
     * unmodified `records_src` into the filtered `records` param.
     * @param {*} param0
     * @param {*} id
     */
    filter_features({commit, state}, id) {
        if(id == null) {
            commit('setRecordsFiltered', state.records_src)
            return
        } else if (id == -1) {
            commit('setRecordsFiltered', [])
            return
        }

        let results = state.records_src.filter(e => {
            if(e.feature_type) {
                return e.feature_type.id === id
            }
        })
        commit('setRecordsFiltered', results)
    },

    /**
     * Search the currently loaded records set.
     * @param {*} param0
     * @param {*} q
     */
    search({commit, state}, q) {
        if(q === "") {
            commit('setRecordsFiltered', state.records_src)
            return
        }

        const rx = new RegExp(q, "i")
        let results = state.records_src.filter(e => {
            return e.title.search(rx) >= 0
        })
        commit('setRecordsFiltered', results)
    }
};

export const mutations = {
    setFeatures(state, record) {
        state.features = record
        state.features_loading = false
    },
    setFeaturesError(state, e) {
        state.features_error = e
        throw new Error(`Error fetching features: ${e}`)
    },
    setFeaturesLoading(state, s) {
        state.features_loading = s
    },
    setRecord(state, record) {
        state.record = record
        state.record_loading = false
    },
    setRecordError(state, e) {
        state.record_error = e
        throw new Error(`Error fetching record: ${e}`)
    },
    setRecordLoading(state, s) {
        state.record_loading = s
    },
    setRecords(state, results) {
        const data = results;
        let records = []
        let groups = []

        for(var i=0; i < data.length; i++) {
            if(data[i].latitude && data[i].longitude) {
                records.push(data[i])
            }
            if (data[i].groups.length > 0) {
                let groupSlugs = data[i].groups.map(g => {
                    return g.name.toLowerCase().replaceAll(' ', '-');
                })
                groupSlugs.forEach(groupSlug => {
                    if (!groups[groupSlug]) {
                        groups[groupSlug] = []
                    }
                    groups[groupSlug].push(data[i])
                })
            }
        }

        state.records_src = records

        state.records = records
        state.records_loaded = true
        state.records_loading = false
        state.record_groups = groups
        state.record_groups_names = Object.keys(groups)
    },
    setRecordsFiltered(state, results) {
        state.records = results
    },
    setRecordSet(state, record_set) {
        let groupNames = Object.keys(state.record_groups)
        let groupName = groupNames.find((gName) => {
            return record_set ? gName.includes(record_set.toLowerCase()) : '';
        })

        if (groupName) {
            state.records = state.record_groups[groupName];
        } else {
            state.records = state.records_src
        }
    },
    setRecordsError(state, e) {
        state.records_error = e
        throw new Error(`Error fetching records: ${e}`)
    },
    setRecordsLoading(state, s) {
        state.records_loading = s
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
