<template>
    <div id="app">
        <Map />
        <Intro v-if="!introDismissed" />
        <SidePanel>
            <router-view />
        </SidePanel>
    </div>
</template>

<script>
import Map from '@/components/Map'
import SidePanel from '@/components/SidePanel'
import Intro from '@/components/Intro'
import Modal from '@/components/Modal.vue'
import { mapGetters } from "vuex"

export default {
    name: 'App',
    components: {
        Map,
        SidePanel,
        Intro,
        Modal,
    },
    computed: {
        ...mapGetters({
            'introDismissed': 'global/introDismissed',
            'site': 'global/site',
        }),
    },
    mounted() {
        this.$store.dispatch('global/site', location.hostname).then(() => {
            this.$store.dispatch('records/fetch_list').then(() => {
                this.$store.dispatch('map/fetchLayers')
                this.$store.dispatch('map/layerDefault')
                this.$store.dispatch('map/mapCoords')
                this.$store.dispatch('map/zoomLimit')
                this.$store.dispatch('map/zoomDefault')
            })

            if (this.$route.params.slug) {
                this.$store.dispatch('global/dismissIntro', true)
                this.$store.dispatch('records/fetch', this.$route.params.slug)
            }
        });
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

#app {
    height: $height-body;
    color: $color-text;
}
</style>
