<template>
<div>
    <a class="tout" :href="url" target="_blank">
        <img v-if="hasImg" :src="link.image.url">
        <div class="text-container" v-bind:class="{'has-image': hasImg}">
            <h5>{{ link.header }}</h5>
            <div v-html="link.text_rendered"></div>
            <span><ArrowIcon /></span>
        </div>
    </a>    
</div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'
import ArrowIcon from "@/assets/svg/link-arrow-right.svg"

export default {
    name: 'LinkBlock',
    components: {
        LoadingIndicator,
        ArrowIcon
    },
    props: {
        link: Object,
        linkType: String
    },
    computed: {
        'url': function () {
            if (this.link.page_link) {
                return this.link.page_link
            } else if (this.link.document_link) {
                return this.link.document_link
            } else if (this.link.link_url) {
                return this.link.link_url
            } else {
                return '#'
            }
        },
        'hasImg': function () {
            return this.link.image && this.link.image.url;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

h5 {
    color: $color-black;
}

.tout {
    width: 100%;

    img {
        max-width: 100%;
    }
}

svg {
    stroke: $marker-gold;
    margin-top: 1rem;
}

.text-container {
    border: 1px solid $marker-gold;
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;

    &.has-image {
        margin-top: -1rem;
        margin-left: auto;
        width: 65%;
    }
}

</style>
