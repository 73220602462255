<template>
     <button class="btn ctl-btn btn-map"
            :class="[{'btn-map-selected': selected}, imgClass, borderClass]"
            @click="changeLayer(layer)">
            <slot>                
                <span class="btn-label">{{ layerName }}</span>
            </slot>
        </button>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    props: {
        imgClass: String,
        selected: Boolean,
        layer: String
    },
    computed: {
        ...mapGetters({
            'site': 'global/site',
            'layerColors': 'map/layerColors',
        }),
        borderClass() {
            let keyName = Object.keys(this.layerColors).find(name => name.includes(this.layer));
            return keyName ? `border-${this.layerColors[keyName]}` : '';
        },
        layerName () {
            let name = Object.keys(this.layerColors).find(name => name.includes(this.layer));
            return name ? name.replaceAll('-', ' ') : this.layer.replaceAll('-', ' ');
        }
    },
    methods: {
        /**
        * Change the map to use the provided layer: `satelite`, `road`, or site-specific map
        */
        changeLayer (layer) {
            this.$router.push({
                path: window.location.pathname,
                hash: this.$stateHash.set('map', layer)
            }, () => {
                if (this.site == 'corinth') {
                    this.$store.dispatch('records/change_record_set', layer)
                }
            })

        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.btn-map {
    background-size: 128px 128px;
    width: 67px;
    height: 67px;
    position: relative;

    .btn-label {
        position: absolute;
        width: 67px;
        right: 0;
        top: 78px;
        font-family: $font-sans;
        text-align: left;
        color: $color-gray;
    }

    &:hover {
        background-size: 128px 128px;
    }

    &[class*=" preview"], &[class^="preview"] {
        box-shadow: 0 0 0 3px $color-gray;
        background-position: center;

        @include map-button-images;
    }

    &.border-green {
        box-shadow: 0 0 0 3px $marker-green;
    }
    &.border-light-blue {
        box-shadow: 0 0 0 3px $marker-light-blue;
    }
    &.border-dark-blue {
        box-shadow: 0 0 0 3px $marker-dark-blue;
    }
    &.border-magenta {
        box-shadow: 0 0 0 3px $marker-magenta;
    }
    &.border-terra-cotta {
        box-shadow: 0 0 0 3px $marker-terra-cotta;
    }
    &.border-gold {
        box-shadow: 0 0 0 3px $marker-gold;
    }
}

</style>