<template>
    <div>
        <section class="block" v-for="field in streamfield" :key="field.id">
            <WysiwygBlock v-if="isText(field)" :block="field" />
            <ImageBlock v-if="isImg(field)" :block="field" />
            <HeadingBlock v-if="field.type == 'heading'" :block="field" />
            <BlockquoteBlock v-if="field.type == 'blockquote'" :block="field" />
            <CarouselBlock v-if="field.type == 'carousel'" :slides="slides(field)" />
        </section>
    </div>
</template>

<script>
import WysiwygBlock from "@/components/blocks/Wysiwyg"
import ImageBlock from "@/components/blocks/Image"
import CarouselBlock from "@/components/blocks/Carousel"
import HeadingBlock from "@/components/blocks/Heading"
import BlockquoteBlock from "@/components/blocks/Blockquote"

export default {
    name: 'StreamField',
    components: {
        WysiwygBlock,
        ImageBlock,
        CarouselBlock,
        HeadingBlock,
        BlockquoteBlock
    },
    props: {
        streamfield: Array
    },
    methods: {
        isText(field) {
            return field.type == 'text' || field.type == 'wysiwyg';
        },
        isImg(field) {
            return field.type == 'image';
        },
        slides(field) {
            return field.value;
        },
        handleClick(event) {
            console.log(event);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

section.block {
    margin: 0 0 $padding-unit-v;
}
</style>
