export const state = () => ({
    filtersOpen: false,
    sidepanelExpanded: false,
    sidepanelOpen: false,
    introDismissed: false,
    site: ''
})

export const getters = {
    filtersOpen: state => state.filtersOpen,
    sidepanelExpanded: state => state.sidepanelExpanded,
    sidepanelOpen: state => state.sidepanelOpen,
    introDismissed: state => state.introDismissed,
    site: state => state.site
};

export const actions = {
    filtersToggle({commit, state}) {
        commit('setFiltersOpenValue', !state.filtersOpen)
    },
    setFiltersOpen({commit, state}, value) {
        commit('setFiltersOpenValue', value)
    },
    setSidepanelExpanded({commit, state}, value) {
        commit('setSidepanelExpandedValue', value)
    },
    setSidepanelOpen({commit, state}, value) {
        commit('setSidepanelOpenValue', value)
    },
    dismissIntro({commit, state}, value) {
        commit('setIntroDismissedValue', value)
    },
    site({commit, state}, value) {
        let siteMap = {
            'corinth.localhost': 'corinth',
            'ccp-dev.phf.upswell.io': 'corinth',
            'www.corinthcomputerproject.org': 'corinth',
            'mtlyk.localhost': 'mt-lykaion',
            'le-dev.phf.upswell.io': 'mt-lykaion',
            'www.lykaionexcavation.org': 'mt-lykaion',
            'park.localhost': 'parrhasian-heritage-park',
            'php-dev.phf.upswell.io': 'parrhasian-heritage-park',
            'staging.parrhasianheritagepark.org': 'parrhasian-heritage-park',
        }
        let site = siteMap[value] || 'parrhasian-heritage-park'

        commit('setSite', site)
    }
};

export const mutations = {
    setFiltersOpenValue(state, value) {
        state.filtersOpen = value
    },
    setSidepanelExpandedValue(state, value) {
        state.sidepanelExpanded = value
    },
    setSidepanelOpenValue(state, value) {
        state.sidepanelOpen = value
    },
    setIntroDismissedValue(state, value) {
        state.introDismissed = value
    },
    setSite(state, value) {
        state.site = value
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
