<template>
<div class="intro" :class="{'displayed': displayed}">
    <Loading v-if="loading || !introduction" />
    <div v-else>
        <div>
            <a class="btn-circle panel-action" @click="close()">
                <span class="sr-only">close</span>
                <IconClose/>
            </a>
            <div class="wrapper-title">
                <h1>{{ introduction.intro_title }}</h1>
                <p class="byline" v-if="introduction.authors.length > 0">by
                    <span class="author" v-for="author in introduction.authors" :key="author.slug">
                        {{ author.name }}
                    </span>
                </p>
            </div>
            <div class="wrapper-content">
                {{ introduction.intro_text }}
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"
import Loading from "@/components/Loading"
import StreamField from "@/components/StreamField"
import IconClose from "@/assets/svg/icon-x.svg";

export default {
    name: 'Intro',
    components: {
        Loading,
        StreamField,
        IconClose
    },
    data() {
        return {
            loading: false,
            introduction: null,
            displayed: false
        }
    },
    computed: {
        ...mapGetters({
            'site': 'global/site',
        })
    },
    methods: {
        format_url(author) {
            if (author) {
                return author.name.toLowerCase().replace(/[^a-z0-9\s]/gi,'').replace(/\s/g, '-');
            } else {
                return ''
            }
        },
        close () {
            this.$store.dispatch('global/dismissIntro', true)
        },
        fadeIn () {
            this.displayed = true;
        },
        getIntro() {
            this.loading = true
            let req = new XMLHttpRequest()
            req.open('GET', `${window.location.origin}/api/pages/?slug=${this.site}-home&type=map_home.HomePageWithMap&fields=intro_title,show_map_color_key,authors(*),intro_text`)
            req.responseType = 'json'
            req.send()
            req.onload = () => {
                if(req.status === 200) {
                    this.introduction = req.response.items[0]
                    this.$store.dispatch('map/setShowKey', req.response.items[0].show_map_color_key)
                } else{
                    console.log(`Search error ${req.status} : ${req.statusText}`)
                }
            }
            req.onerror = () => {
                console.log(`introduction request failed.`)
            }
            this.loading = false;
        }
    },
    mounted() {
        setTimeout(() => {
            this.fadeIn();
        }, 2500);
    },
    created () {
        if (this.site) {
            this.getIntro();
        }
    },
    watch: {
        'site': function (newSite, oldSite) {
          if (newSite) {
              this.getIntro();
          }  
        },
        '$route.name': function(hash) {
            this.close()
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";
.intro {
    position: fixed;
    top: $height-header;
    max-width: 50%;
    width: 500px;
    right: 0;
    color: $color-light-off-white;
    background: $color-text;
    padding: 2rem 0;

    opacity: 0;
    transition: opacity 250ms ease-in-out;

    &.displayed {
        opacity: 1;
    }

    a.panel-action {
        top: 0;
        position: absolute;
        right:  $padding-unit-v;
        margin-top: $padding-unit-v;
        transition: $transition-default;
    }

    .wrapper-title {
        padding-top: 0;
        padding-bottom: 0;

        h1 {
            padding-bottom: .45rem;
            font-weight: 400;
        }

        .byline {
            color: $color-light-off-white;

            .author{
                display: inline;
                margin: 0;
                margin-right: .35rem;   

                &:not(:last-child):after {
                    content: ',';
                }
            }
        }
    }

    @media only screen and (max-width: $mq-max-width-mobile) {
        max-width: 70%;
    }
}
</style>