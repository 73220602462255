import Vue from "vue";
import Vuex from "vuex";

import global from "./global";
import records from "./records";
import map from "./map";
import subvoce from "./subvoce";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        global,
        records,
        subvoce,
        map
    }
});
