<template>
    <div>
        <div ref="map" id="map" :class="classes"></div>
        <div class="ctl-wrapper ctl-zoom">
            <button class="btn btn-fill ctl-btn btn-zoom-out" :disabled="!canZoomOut" @click="onZoomOut">
                <IconMinus />
            </button>
            <button class="btn btn-fill ctl-btn btn-zoom-in" :disabled="!canZoomIn" @click="onZoomIn">
                <IconPlus />
            </button>
        </div>
        <div class="ctl-wrapper ctl-selected">
            <div ref="selectedMap" id="selected-map">
                <h2>View</h2>
            </div>
        </div>
        <div class="ctl-wrapper ctl-map">
            <ViewButton layer='satelite' :imgClass='`preview-satelite-${site}`' :selected="layerSelected == 'satelite'">
                <span class="btn-label">Satellite View</span>
            </ViewButton>
            <ViewButton layer='street' :imgClass='`preview-street-${site}`' :selected="layerSelected == 'street'">
                <span class="btn-label">Street View</span>
            </ViewButton>
            <template v-if="activeGroups">
                <ViewButton v-for="label in activeLayers" :key="label" :layer='label' :imgClass='`preview-${label}`'
                    :selected="layerSelected == label">
                </ViewButton>
            </template>
        </div>
        <div v-if="showKey" class="ctl-wrapper ctl-key">
            <span v-for="g in activeGroups" :key="g.slug" class="key-item" :class="layerColors[g.slug]">
                {{ g.name }}
            </span>
        </div>
        <!-- <div class="ctl-wrapper ctl-filter">
        <button class="btn btn-fill ctl-btn btn-filter"
            @click="onFiltersOpen">Filter</button>
    </div> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import L from "leaflet"

import ViewButton from "@/components/ViewButton.vue"
import IconMinus from "@/assets/svg/icon-minus.svg"
import IconPlus from "@/assets/svg/icon-plus.svg"

export default {
    name: "SimpleMap",
    components: {
        IconMinus,
        IconPlus,
        ViewButton,
    },
    data() {
        return {
            map: null,
            mapPreview: null,
            markers: {},
            selectedMarker: null,
            previousMarker: null,
            markerLayerGroup: null,
            layerCurrent: null,
            layerSelected: null,
            markerPoint: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 10.82"><path d="M945.57,517c9.48,0,10,10.82,10,10.82s.6-10.82,10.87-10.82Z" transform="translate(-945.46 -517)"/></svg>'
        };
    },
    computed: {
        ...mapGetters({
            'record': 'records/record',
            'records': 'records/records',
            'expanded': 'global/sidepanelExpanded',
            'open': 'global/sidepanelOpen',
            'layers': 'map/layers',
            'activeGroups': 'map/activeGroups',
            'layerDefault': 'map/layerDefault',
            'site': 'global/site',
            'coords': 'map/coords',
            'zoom': 'map/zoomLimit',
            'zoomDefault': 'map/zoomDefault',
            'showKey': 'map/showKey',
            'layerColors': 'map/layerColors',
        }),
        center() {
            return this.coords['center'];
        },
        boundaryTL() {
            return this.coords['bTL'];
        },
        boundaryBR() {
            return this.coords['bBR'];
        },
        canZoomIn() {
            return this.map ? this.map.getZoom() != this.map.getMaxZoom() : false
        },
        canZoomOut() {
            return this.map ? this.map.getZoom() != this.map.getMinZoom() : false
        },
        classes() {
            return [
                'map',
                this.width,
                'leaflet-container leaflet-retina leaflet-fade-anim leaflet-grab leaflet-touch-drag'
            ]
        },
        width() {
            if (!this.open) {
                return 'panel-closed'
            } else if (this.open && !this.expanded) {
                return 'panel-open'
            } else {
                return 'panel-open-expanded'
            }
        },
        activeLayers() {
            let activeGroups = [];
            if (!this.layers.labels || !this.activeGroups) return []
            if (this.site == 'corinth') {
                this.layers.labels.forEach(l => {
                    this.activeGroups.forEach(g => {
                        if (g.name.includes(l)) activeGroups.push(l)
                    })
                })
            } else {
                activeGroups = this.layers.labels;
            }
            return activeGroups;
        }
    },
    methods: {
        // Add points to the Map, triggered on mounted, or watch:records
        addPoints() {
            if (this.markerLayerGroup) {
                this.markerLayerGroup.clearLayers();
            }

            this.markerLayerGroup = L.layerGroup().addTo(this.map)

            this.records.forEach(record => {

                if (record.groups.length) {
                    let hide = [false];
                    record.groups.forEach(g => {
                        hide.push(g.active);
                    })
                    if (!hide.includes(true)) return;
                }

                const icon = L.divIcon({
                    className: 'marker',
                    html: `<div class="marker-pin"><div class="marker-pin-id ${this.markerColor(record)}">${record.identifier}</div>${this.markerPoint}</div>`,
                    iconSize: [34, 32],
                    iconAnchor: [17, 32]
                });

                const m = L.marker([record.latitude, record.longitude],
                    {
                        title: record.title,
                        icon: icon
                    })
                    .addTo(this.markerLayerGroup)
                    .on('click', this.onMarkerClick)

                m._icon.id = record.slug

                this.markers[record.slug] = m
            })

            // Check if a record is loaded and navigate to it
            this.onRecordChange()
        },
        markerColor(record) {
            if (record.groups.length == 0) {
                return '';
            }
            if (this.site == 'corinth') {
                let color = "";
                let group = record.groups.find(r => r.name.includes(this.layerSelected))
                if (group) {
                    color = group.marker_color;
                } else {
                    let active = record.groups.filter(g => g.active)
                    color = active[0].marker_color;
                }
                return color
            } else {
                let color = '';
                if (record.groups.length) {
                    let active = record.groups.filter(g => g.active)
                    color = active[0].marker_color;
                }
                return color;
            }
        },
        // Helper to convert coordinates into a Leaflet `L.latLng` point,
        getPoint(lat, long) {
            return lat && long ? L.latLng(lat, long) : L.latLng(0, 0)
        },
        onLayerChanged(id) {
            if (!id) {
                return
            }

            if (id in this.layers) {
                this.layerSelected = id
                if (this.layerCurrent) {
                    try {
                        this.map.removeLayer(this.layerCurrent)
                    }
                    catch (err) {
                        console.error(err)
                    }
                }
                this.layerCurrent = this.layers[id]
                this.map.addLayer(this.layerCurrent)

                // change the selected view image
                this.$refs.selectedMap.className = "";
                let selectedClass = this.layerSelected;
                if (this.layerSelected == 'street' || this.layerSelected == 'satelite') {
                    selectedClass += `-${this.site}`;
                }
                this.$refs.selectedMap.classList.add(`preview-${selectedClass}`);
            } else {
                console.error(`Error: Cannot load map, layer with id ${id} not found`)
            }
        },
        onFiltersOpen() {
            this.$store.dispatch('global/filtersToggle', true)
        },
        onMarkerClick(e) {
            const latLong = [e.target._latlng.lat, e.target._latlng.lng]

            this.map.flyTo(latLong, this.zoom.max)

            this.$router.push({ name: 'record', params: { slug: e.target._icon.id } }, () => { })
            this.updateMarkerState(e.target._icon)
        },
        updateMarkerState(newSelectedMarker) {
            this.previousMarker = this.previousMarker ? this.selectedMarker : newSelectedMarker;
            this.selectedMarker = newSelectedMarker;

            if (this.previousMarker != null) {
                this.previousMarker.classList.remove('selected-marker');
            }

            this.selectedMarker.classList.add('selected-marker');
        },

        /**
         * Handle when the selected record changes
         */
        onRecordChange() {
            if (this.record) {
                if (this.record.latitude && this.record.longitude) {
                    if (this.site == 'parrhasian-heritage-park') {
                        this.map.flyTo(this.markers[this.record.slug].getLatLng(), this.zoom.max)
                    } else {
                        this.map.flyTo(this.markers[this.record.slug].getLatLng(), this.zoom.max)
                    }
                }

                let marker = document.querySelector(`#${this.record.slug}`)
                this.updateMarkerState(marker);
            }
        },

        /**
         * When the viewport resizes, Leaflet needs to have it's size
         * invalidated so that it can internally update it's dims.
         */
        onResize() {
            let m = this.map
            if (this.map) {
                setTimeout(function () {
                    m.invalidateSize()
                }, 400);
            }
        },
        onZoomIn() {
            this.map.setZoom(this.map.getZoom() + .5)
        },
        onZoomOut() {
            this.map.setZoom(this.map.getZoom() - .5)
        },
    },
    mounted() {
        const select = this.$stateHash.get('map')

        // Patch for broken icons
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
    },
    watch: {
        '$route.hash': function (hash) {
            if (!this.map) return;
            this.onLayerChanged(this.$stateHash.get('map'))
        },
        expanded() {
            this.onResize()
        },
        open() {
            this.onResize()
        },
        // record() {
        //     this.onRecordChange()
        // },
        records() {
            if (!this.map) return;
            if (this.site == 'corinth') {
                this.$store.dispatch('records/change_record_set', this.layerSelected)
            }
            this.addPoints()
        },
        layers() {
            if (!this.map) return;
            this.onLayerChanged(this.$stateHash.get('map') || this.layerDefault)
        },
        coords() {
            this.map = L.map('map', {
                center: this.center,
                minZoom: this.zoom.min,
                maxZoom: this.zoom.max,
                zoom: this.zoomDefault,
                zoomDelta: .5,
                zoomSnap: .5,
                zoomControl: false
            })

            this.onLayerChanged(this.$stateHash.get('map') || this.layerDefault)
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

$zoom-btn-dims: 50px;

#map.map {
    height: $height-body;
    left: 0;
    position: absolute;
    top: $height-header;
    transition: $transition-default;
    z-index: 0;
    margin-top: 0;

    &.leaflet-container {
        background-color: tan;
        background-image: url('../assets/img/map-background.png');
        background-repeat: repeat;
        background-size: 50px 50px;
    }

    &.panel-closed {
        width: 100%;
    }

    &.panel-open {
        width: $width-map;
    }

    &.panel-open-expanded {
        width: $width-map-expanded;
    }
}

@media only screen and (max-width: $mq-max-width-mobile) {
    #map.map {
        width: 100%;

        &.panel-open {
            height: $map-mobile-height-body;
            width: 100%;
        }

        &.panel-open-expanded {
            height: $map-mobile-height-body;
            width: 100%;
        }
    }
}


.ctl-wrapper {
    border-radius: 4px;
    position: fixed;
}

.ctl-btn {
    background: linear-gradient(180deg, rgba(176, 187, 193, .8) 0%, rgba(140, 157, 166, .8) 100%);
    display: block;
    float: left;
    margin: 10px;
    outline: none;
    transition: $transition-default;
    height: 30px;
    width: 30px;

    &:focus {
        outline: 2px solid $color-gray-light;
        outline-offset: 5px;
    }
}

.btn-filter {
    font-variant: small-caps;
    text-transform: lowercase;
    width: 67px;
}

.ctl-key {
    top: 348px;
    left: 15px;
    display: flex;
    flex-direction: column;

    .key-item {
        width: 100px;
        height: 50px;
        color: white;
        font-family: $font-sans;

        font-size: .75rem;
        text-align: center;
        line-height: 1.4;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        &:last-child {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &.terra-cotta {
            background-color: $marker-terra-cotta;
        }

        &.magenta {
            background-color: $marker-magenta;
        }

        &.light-blue {
            background-color: $marker-light-blue;
        }

        &.dark-blue {
            background-color: $marker-dark-blue;
        }

        &.green {
            background-color: $marker-green;
        }

        &.gold {
            background-color: $marker-gold;
        }
    }
}

.ctl-zoom {
    box-shadow: $glow-ui-outter;
    left: 15px;
    top: $height-header + 70px;

    .btn-zoom-in {
        margin: 0;
        padding-top: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: $zoom-btn-dims;
        height: $zoom-btn-dims;
        border-left: solid 1px $color-gray-light;

        svg {
            path {
                stroke-width: 2;
            }
        }
    }

    .btn-zoom-out {
        line-height: 0;
        margin: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: $zoom-btn-dims;
        height: $zoom-btn-dims;

        svg {
            path {
                stroke-width: 2;
            }
        }
    }
}


@supports (-webkit-touch-callout: none) {
    .btn-zoom-in {
        svg {
            margin-left: -5px;
        }
    }

    .btn-zoom-out {
        svg {
            margin-left: -4px;
        }
    }
}

.ctl-filter {
    left: 15px;
    top: $height-header + 62px + 85px;
}

.ctl-map {
    left: 140px;
    top: $height-header + $zoom-btn-dims + 100px;
    height: 165px;
    box-sizing: border-box;
    padding: 10px 10px 8px;


    .btn-map {
        display: none;
    }

    &:hover {
        background: $color-white;

        .btn-map {
            display: block;
        }
    }
}

.ctl-selected {
    width: 130px;
    left: 15px;
    top: $height-header + $zoom-btn-dims + 100px;
    height: 130px;
    box-sizing: border-box;

    &:hover {
        +.ctl-map {
            background: $color-white;

            .btn-map {
                display: block;
            }
        }
    }

    #selected-map {
        border-radius: 4px;
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);

        h2 {
            margin-top: auto;
            color: $color-white;
            font-family: $font-sans;
            background: linear-gradient(0deg, #2b2b2b -15%, transparent 105%);
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }

        &[class*=" preview"],
        &[class^="preview"] {
            box-shadow: 0 0 0 3px $color-gray;
            background-position: center;
            background-size: 150%;

            @include map-button-images;
        }
    }
}

.marker-pin {
    height: 100%;
    width: 100%;

    .marker-pin-id {
        background-color: $color-blue-darker;
        border-radius: 3px;
        color: $color-white;
        display: block;
        height: 18px;
        padding: 2px;
        text-align: center;
        width: 30px;
        box-shadow: 0 0 5px rgba(14, 33, 48, 0.6);

        &.terra-cotta {
            background-color: $marker-terra-cotta;

            +svg {
                fill: $marker-terra-cotta;
            }
        }

        &.magenta {
            background-color: $marker-magenta;

            +svg {
                fill: $marker-magenta;
            }
        }

        &.light-blue {
            background-color: $marker-light-blue;

            +svg {
                fill: $marker-light-blue;
            }
        }

        &.dark-blue {
            background-color: $marker-dark-blue;

            +svg {
                fill: $marker-dark-blue;
            }
        }

        &.green {
            background-color: $marker-green;

            +svg {
                fill: $marker-green;
            }
        }

        &.gold {
            background-color: $marker-gold;

            +svg {
                fill: $marker-gold;
            }
        }
    }

    svg {
        display: block;
        fill: $color-blue-darker;
        left: 7px;
        position: relative;
        width: 20px
    }
}

.marker {
    &:hover {
        z-index: 1000 !important;

        .marker-pin {
            transform: scale(1.2);
            transition: transform 200ms ease-out;
            transform-origin: bottom center;
        }
    }

    &.selected-marker {
        .marker-pin {
            transform: scale(1.2);
            transition: transform 200ms ease-out;

            .marker-pin-id {
                background-color: $color-white;
                border-radius: 3px;
                color: $color-blue-darker;
                display: block;
            }

            svg {
                fill: $color-white;
            }
        }
    }
}
</style>
